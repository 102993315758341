export const photos = [{
    src: "assets/t01/t1.jpg",
    width: 4,
    height: 3
},
{
    src: "assets/t02/t2.png",
    width: 4,
    height: 3
},
{
    src: "assets/t03/t3.png",
    width: 4,
    height: 3
},
{
    src: "assets/t04/t4.jpg",
    width: 4,
    height: 3
},
{
    src: "assets/t05/t5.jpeg",
    width: 4,
    height: 3
},
{
    src: "assets/t06/t6.png",
    width: 4,
    height: 3
},
{
    src: "assets/t07/t7.jpeg",
    width: 7,
    height: 3
},
{
    src: "assets/t08/t8.jpeg",
    width: 4,
    height: 3
},
{
    src: "assets/t09/t9.jpeg",
    width: 4,
    height: 3
},
{
    src: "assets/t10/t10.jpeg",
    width: 4,
    height: 3
},
{
    src: "assets/t11/t11.JPG",
    width: 4,
    height: 5
}
];

