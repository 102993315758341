const equipe = [
    {
        id: 1,
        nome: "Ana Paula",
        capa: "assets/equipe/ana.jpeg"
    },
    {
        id: 2,
        nome: "Guilherme Anjos",
        capa: "assets/equipe/gui.jpeg"
    },
    {
        id: 3,
        nome: "Danielle Madrid",
        capa: "assets/equipe/danielle.jpg"
    },
    {
        id: 5,
        nome: "Fernando Fernandes",
        capa: "assets/equipe/fernando.jpeg"
    },
    {
        id: 6,
        nome: "Rafael Gustavo",
        capa: "assets/equipe/rafa.jpeg"
    },
    {
        id: 7,
        nome: "Rafael Prikladnicki ",
        capa: "assets/equipe/rafael.jpg"
    },
    {
        id: 8,
        nome: "Maira Petrini ",
        capa: "assets/equipe/maira.jpg"
    },
]

export default equipe